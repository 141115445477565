import React from 'react';
import { Box, Container, Typography, Divider, Paper, Grid } from '@mui/material';

const Tutorials = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h2" gutterBottom align="center">
        Tutorials
      </Typography>
      <Typography variant="h6" gutterBottom align="center">
        Explore our tutorials to get started with our platform.
      </Typography>
      <Divider sx={{ my: 4 }} />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h4" gutterBottom>
              Tutorial Title
            </Typography>

            <Typography variant="h6" gutterBottom>
              Steps
            </Typography>
            <Typography variant="body1" paragraph>
              1. Step one: Description of the first step.
            </Typography>
            <Typography variant="body1" paragraph>
              2. Step two: Description of the second step.
            </Typography>
            <Typography variant="body1" paragraph>
              3. Step three: Description of the third step.
            </Typography>
            <Typography variant="body1" paragraph>
              4. Step four: Description of the fourth step.
            </Typography>
            <Typography variant="body1" paragraph>
              5. Step five: Description of the fifth step.
            </Typography>

            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
              <Box
                component="iframe"
                src="https://www.youtube.com/embed/dQw4w9WgXcQ"
                title="Tutorial Video"
                width="100%"
                height="400"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                sx={{
                  maxWidth: '600px',
                  aspectRatio: '16/9',
                  borderRadius: '8px',
                }}
              />
            </Box>

            <Typography variant="h6" gutterBottom>
              Features
            </Typography>
            <Typography variant="body1" paragraph>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ac orci vitae risus tincidunt ultrices. Praesent auctor justo vel lectus rhoncus, at congue velit facilisis. Nam ac est sed dui vehicula tincidunt. Donec feugiat sapien eu nulla ullamcorper, ac congue nisi tincidunt. Cras ac magna eu tortor blandit eleifend. Phasellus vestibulum ligula vel lacus elementum, id efficitur magna venenatis.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Tutorials;

