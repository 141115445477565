import React from 'react';
import { Drawer, List, ListItem, ListItemText, Typography, Divider, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';

const SidebarMenu = () => {
    const navigate = useNavigate();
  
    const handleNavigation = (path) => {
      const [route, hash] = path.split('#');
      navigate(route);
  
      if (hash) {
        setTimeout(() => {
          document.getElementById(hash)?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    };

    return (
      <Drawer
        variant="permanent"
        sx={{
          width: 240,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
              width: 240,
              boxSizing: 'border-box',
              bgcolor: '#007AFF', // Azul oscuro
              color: '#FFFFFF', // Texto blanco
              display: 'flex',
              flexDirection: 'column',
              fontFamily: 'Inter, sans-serif', // Aplicar la fuente Inter
          },
      }}
      >
        <Box sx={{ p: 2, bgcolor: '#007AFF', color: '#FFFFFF' }}> {/* Fondo azul oscuro y texto blanco */}
          <Typography variant="h6" sx={{ fontWeight: 'bold' }} noWrap>
            MARKET RGT
          </Typography>
        </Box>
        <Divider sx={{ bgcolor: '#CCCCCC' }} /> {/* Divider gris claro */}
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
            paddingRight: '8px', // Espacio para el scrollbar
            '&::-webkit-scrollbar': {
              width: '8px', // Ancho del scrollbar
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#2181E2', // Fondo del track del scrollbar
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#1E60B3', // Color del thumb del scrollbar
              borderRadius: '4px', // Bordes redondeados del thumb
            },
          }}
        >
          <List>
            <Accordion sx={{ bgcolor: '#2181E2', color: '#FFFFFF' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#FFFFFF' }} />} // Icono blanco
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">White Papers</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#2181E2', color: '#FFFFFF' }}>
                <List>
                  <ListItem 
                    button 
                    onClick={() => handleNavigation('/whitepapers#create-rgt-account')}
                    sx={{
                      '&:hover': {
                        bgcolor: '#1E60B3', // Azul más claro para el hover
                      },
                    }}
                  >
                    <ListItemText primary="Create RGT Account" sx={{ color: '#FFFFFF' }} />
                  </ListItem>
                  <ListItem 
                    button 
                    onClick={() => handleNavigation('/whitepapers#Introduction')}
                    sx={{
                      '&:hover': {
                        bgcolor: '#1E60B3', // Azul más claro para el hover
                      },
                    }}
                  >
                    <ListItemText primary="Introduction" sx={{ color: '#FFFFFF' }} />
                  </ListItem>
                  <ListItem 
                    button 
                    onClick={() => handleNavigation('/whitepapers#Objectives')}
                    sx={{
                      '&:hover': {
                        bgcolor: '#1E60B3', // Azul más claro para el hover
                      },
                    }}
                  >
                    <ListItemText primary="Objectives" sx={{ color: '#FFFFFF' }} />
                  </ListItem>
                  <ListItem 
                    button 
                    onClick={() => handleNavigation('/whitepapers#Key-Features')}
                    sx={{
                      '&:hover': {
                        bgcolor: '#1E60B3', // Azul más claro para el hover
                      },
                    }}
                  >
                    <ListItemText primary="Key Features" sx={{ color: '#FFFFFF' }} />
                  </ListItem>
                  <ListItem 
                    button 
                    onClick={() => handleNavigation('/whitepapers#Technology-Stack')}
                    sx={{
                      '&:hover': {
                        bgcolor: '#1E60B3', // Azul más claro para el hover
                      },
                    }}
                  >
                    <ListItemText primary="Technology Stack" sx={{ color: '#FFFFFF' }} />
                  </ListItem>
                  <ListItem 
                    button 
                    onClick={() => handleNavigation('/whitepapers#Roadmap')}
                    sx={{
                      '&:hover': {
                        bgcolor: '#1E60B3', // Azul más claro para el hover
                      },
                    }}
                  >
                    <ListItemText primary="Roadmap" sx={{ color: '#FFFFFF' }} />
                  </ListItem>
                  <ListItem 
                    button 
                    onClick={() => handleNavigation('/whitepapers#Conclusion')}
                    sx={{
                      '&:hover': {
                        bgcolor: '#1E60B3', // Azul más claro para el hover
                      },
                    }}
                  >
                    <ListItemText primary="Conclusion" sx={{ color: '#FFFFFF' }} />
                  </ListItem>
                  <ListItem 
                    button 
                    onClick={() => handleNavigation('/whitepapers#Contact-Information')}
                    sx={{
                      '&:hover': {
                        bgcolor: '#1E60B3', // Azul más claro para el hover
                      },
                    }}
                  >
                    <ListItemText primary="Contact Information" sx={{ color: '#FFFFFF' }} />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ bgcolor: '#2181E2', color: '#FFFFFF' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#FFFFFF' }} />} // Icono blanco
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography variant="h6">Tutorials</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: '#2181E2', color: '#FFFFFF' }}>
                <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
                  ¡Coming Soon!
                </Typography>
              </AccordionDetails>
            </Accordion>
          </List>
        </Box>
      </Drawer>
    );
};

export default SidebarMenu;