import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
import theme from './theme'; // Asegúrate de que la ruta sea correcta

const GlobalStyles = `
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
  
  body {
    font-family: 'Inter', 'Roboto', 'Arial', sans-serif;
  }
`;

const AppWithGlobalStyles = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <style>
        {GlobalStyles}
      </style>
      <App />
    </ThemeProvider>
  </StyledEngineProvider>
);

ReactDOM.render(
  <Router>
    <AppWithGlobalStyles />
  </Router>,
  document.getElementById('root')
);


