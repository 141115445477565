import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'; 
import WhitePaperTemplate from './components/WhitePaperTemplate';
import Tutorials from './components/Tutorials';
import SidebarMenu from './components/SidebarMenu';
import { Box } from '@mui/material';

const App = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <SidebarMenu />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
      >
        <Routes>
          {/* Redirecciona la ruta raíz "/" a "/whitepapers" */}
          <Route path="/" element={<Navigate to="/whitepapers" replace />} />
          <Route path="/whitepapers" element={<WhitePaperTemplate />} />
          <Route path="/tutorials" element={<Tutorials />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default App;
