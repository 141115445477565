import React from 'react';
import { Box, Container, Typography, Divider, Paper, Link } from '@mui/material';

const WhitePaperTemplate = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ padding: 2, fontFamily: 'Inter, sans-serif' }}>
        <Typography variant="h2" gutterBottom align="center">
          Welcome to Ronin Gaming Tools
        </Typography>
        <Divider sx={{ my: 4 }} />

        {/* Sección 1: Introducción */}
        <Box >
          <Typography variant="h3" sx={{ fontWeight: 'bold' }} gutterBottom>
            Ronin Gaming Tools on Pixels Online
          </Typography>
          <Typography variant="body1" paragraph>
            The digital landscape of Pixels Online is evolving, and to keep pace with the demands for secure, efficient, and use farmer-friendly transactions, we are introducing the Safe Market P2P platform. This whitepaper outlines the objectives, features, and technologies behind the Safe Market P2P, a peer-to-peer marketplace designed to enhance the trading experience within Pixels Online while ensuring robust security and user trust.
          </Typography>
        </Box>

        {/* Sección 2: Crear tu cuenta RGT */}
        <Box id="create-rgt-account" sx={{ mt: 4 }}>
          <Typography variant="h3" sx={{ fontWeight: 'bold' }} gutterBottom>
            Create Your RGT Account
          </Typography>
          <Typography variant="body1" paragraph>
            Before you can use our Safe Market, you’ll need to create an account with your Ronin Wallet attached to your Pixels Online account.
          </Typography>
          <Typography variant="body1" paragraph>
          <span style={{ fontWeight: 'bold' }}>1:</span> Navigate to <Link href="https://pixels.roningamingtools.xyz" target="_blank" rel="noopener noreferrer">pixels.roningamingtools.xyz</Link>.
          </Typography>
          <Typography variant="body1" paragraph>
          <span style={{ fontWeight: 'bold' }}>2:</span> Click on "Connect Wallet" in the lower left corner of the site.
          </Typography>
          <Typography variant="body1" paragraph>
          <span style={{ fontWeight: 'bold' }}>3:</span> Sign with your Ronin Wallet (Safe).
          </Typography>
        </Box>

        {/* Sección 3: Términos de servicio */}
        <Box sx={{ mt: 4 }}>
          <Typography   variant="h3" sx={{ fontWeight: 'bold' }}  gutterBottom>
            Terms of Service
          </Typography>

          {/* Sub-sección: Introducción */}
          <Typography id="Introduction" variant="h4"  gutterBottom sx={{ mt: 3  , fontWeight: 'bold'}}>
          <span style={{ fontWeight: 'bold' }}>1. </span> Introduction
          </Typography>
          <Typography variant="body1" paragraph>
            Pixels Online is a dynamic virtual world where users engage in a variety of activities, including trading virtual items and other in-game commodities. As the popularity of peer-to-peer (P2P) transactions grows, so does the need for a secure, transparent, and efficient marketplace. RGT Safe Market P2P aims to fulfill these needs by providing a dedicated platform for seamless and safe transactions.
          </Typography>
          {/* Sub-sección: Objetivos */}
          <Typography id="Introduction" variant="h4"  gutterBottom sx={{ mt: 3  , fontWeight: 'bold'}}>
          <span style={{ fontWeight: 'bold' }}>2.</span> Objectives
          </Typography>
          <Typography variant="body1" paragraph>
          Enhanced Security: To offer a secure trading environment that protects users from fraud, scams, and unauthorized access.
          User Farmer Friendly Interface: To create an intuitive interface that simplifies the process of listing, buying, and selling coins.
          Efficient Transactions: To provide a fast and reliable system for executing trades with minimal delays and errors.
          </Typography>
          <Typography id="Key-Features" variant="h4" gutterBottom sx={{ mt: 3 , fontWeight: 'bold'}}>
          <span style={{ fontWeight: 'bold' }}>3.</span>Key Features
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mt: 3 , fontWeight: 'bold'}}>
          <span style={{ fontWeight: 'bold' }}>3.1.</span>Secure Transactions
          </Typography>
          <Typography variant="body1" paragraph>
          <span style={{ fontWeight: 'bold' }}>Smart Contracts:</span>
          Leveraging blockchain technology, Safe Market P2P utilizes smart contracts to automate and secure transactions. Smart contracts are self-executing contracts with terms directly written into code, ensuring that transactions are executed automatically.
          Escrow System: An escrow mechanism holds the buyer’s payment until both parties fulfill their obligations. This mitigates risks of fraud by ensuring that neither party can access funds until the terms of the transaction are met.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mt: 3 , fontWeight: 'bold'}}>
          <span style={{ fontWeight: 'bold' }}>3.2.</span>User Verification and Reputation
          </Typography>
          <Typography variant="body1" paragraph>
          <span style={{ fontWeight: 'bold' }}>Identity Verification:</span>
          Users are required to undergo a verification process to enhance trust within the marketplace. This includes linking to verified Pixels accounts with active VIP Membership.
          Pixels Reputation System: A comprehensive reputation system allows us to rate and review the users, not having an active VIP Membership will automatically not autorized to trade or use our service.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mt: 3 , fontWeight: 'bold'}}>
          <span style={{ fontWeight: 'bold' }}>3.3.</span>Transparent and Intuitive Interface
          </Typography>
          <Typography variant="body1" paragraph>
          <span style={{ fontWeight: 'bold' }}>Dashboard:</span>
           Users have access to a centralized dashboard where they can create or manage their listings orders.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mt: 3 , fontWeight: 'bold'}}>
          <span style={{ fontWeight: 'bold' }}>3.4.</span>Compliance and Regulation
          </Typography>
          <Typography variant="body1" paragraph>
          <span style={{ fontWeight: 'bold' }}>Data Privacy:</span>
          Safe Market P2P adheres to strict data privacy regulations, ensuring that user data is protected and only used for intended purposes.
          Regulatory Compliance: The platform is designed to comply with applicable financial regulations and standards, providing a secure trading environment in line with legal requirements.
          </Typography>
          <Typography id="Technology-Stack" variant="h4" gutterBottom sx={{ mt: 3 , fontWeight: 'bold'}}>
          <span style={{ fontWeight: 'bold' }}>4.</span>Technology Stack
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mt: 3 , fontWeight: 'bold'}}>
            4.1 Blockchain Integration
          </Typography>
          <Typography variant="body1" paragraph>
          <span style={{ fontWeight: 'bold' }}>Ronin Blockchain:</span>
           Safe Market P2P is built on the Ronin blockchain, which provides a decentralized and secure ledger for recording transactions and managing smart contracts.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mt: 3 , fontWeight: 'bold'}}>
           4.2 Encryption and Security Protocols
          </Typography>
          <Typography variant="body1" paragraph>
          <span style={{ fontWeight: 'bold' }}>End-to-End Encryption:</span>
          All data exchanged between users and the platform is encrypted using advanced encryption standards, safeguarding against unauthorized access.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mt: 3 , fontWeight: 'bold'}}>
           4.3 Scalability and Performance 
          </Typography>
          <Typography variant="body1" paragraph>
          <span style={{ fontWeight: 'bold' }}>Scalable Infrastructure:</span>
          The platform utilizes scalable cloud infrastructure to handle high volumes of transactions and maintain performance during peak usage times.
          Low Latency: Optimized algorithms and server architecture ensure fast transaction processing and minimal latency.
          </Typography>
          <Typography id="Roadmap" variant="h4" gutterBottom sx={{ mt: 3 , fontWeight: 'bold'}}>
           5. Roadmap
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mt: 3 , fontWeight: 'bold'}}>
           5.1 Development Phases
          </Typography>
          <Typography variant="body1" paragraph>
          <span style={{ fontWeight: 'bold' }}>Phase 1:</span> Design and Prototyping (Q1 2024): Conceptualize and design the platform, including user interfaces and security features.
            <br />
            <span style={{ fontWeight: 'bold' }}>Phase 2:</span> Development and Testing (Q2 2024): Develop core features, integrate blockchain technology, and conduct extensive testing to ensure functionality and security.
            <br />
            <span style={{ fontWeight: 'bold' }}>Phase 3</span> Beta Launch (Q3 2024): Release a beta version of Safe Market P2P for user testing and feedback, making adjustments as needed.
            <br />
            <span style={{ fontWeight: 'bold' }}>Phase 4:</span> Full Launch (Q4 2024): Officially launch Safe Market P2P to the public, supported by marketing and user education initiatives.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mt: 3 , fontWeight: 'bold'}}>
           5.2 Continuous Improvement
          </Typography>
          <Typography variant="body1" paragraph>
          <span style={{ fontWeight: 'bold' }}>User Feedback:</span>
            User Feedback: Regularly gather and analyze user feedback to make iterative improvements and enhancements.
            Updates and Upgrades: Continuously update the platform with new features and security enhancements to adapt to emerging trends and technologies.
          </Typography>
          <Typography id="Conclusion" variant="h4" gutterBottom sx={{ mt: 3 , fontWeight: 'bold'}}>
          6. Conclusion
          </Typography>
          <Typography variant="body1" paragraph>
          Secure Market P2P for Pixels Online is poised to transform the way users engage in peer-to-peer transactions within the virtual world. By prioritizing security, transparency, and user experience, Safe Market P2P aims to set a new standard for digital marketplaces and foster a thriving, trustworthy trading environment.
          </Typography>

          <Box id="contact-information">
          <Paper elevation={3} sx={{ padding: 2 }}>
          <Typography id="Contact-Information" variant="h4" sx={{ fontWeight: 'bold' }} gutterBottom>
             Contact Information and Additional Resources
          </Typography>
            <Typography variant="body1" paragraph>
              For further details or inquiries, please contact:
            </Typography>
            <Typography variant="body1" paragraph>
              Discord: <Link href="https://discord.com/invite/dwE4AvN5" target="_blank">Ronin Gaming Tools</Link>
            </Typography>
            <Typography variant="body1" paragraph>
              Website: <Link href="https://pixels.roningamingtools.xyz" target="_blank">pixels.roningamingtools.xyz</Link>
            </Typography>
            <Typography variant="body1" paragraph>
              X: <Link href="https://x.com/ToolsRonin" target="_blank">@ToolsRonin</Link>
            </Typography>
             <Typography variant="body1" paragraph>
              Multisig: <Link href="https://app.roninchain.com/address/0xb3880ca04b4e702757b8d71ab1fec2d7519fe463" target="_blank">0xb3880ca04b4e702757b8d71ab1fec2d7519fe463</Link>
            </Typography>
          </Paper>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Paper elevation={1} sx={{ padding: 2, backgroundColor: '#f5f5f5' }}>
            <Typography variant="body2" align="center">
              This whitepaper serves as a foundational document for introducing Ronin Gaming Tools [Safe Market P2P] and highlights the strategic approach to developing a secure and efficient trading platform for Pixels Online.
            </Typography>
          </Paper>
        </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default WhitePaperTemplate;