import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Color azul para el menú
    },
    secondary: {
      main: '#dc004e', // Color secundario (puedes cambiarlo si es necesario)
    },
    background: {
      default: 'white', // Fondo blanco
    },
    text: {
      primary: '#000000', // Texto negro
      secondary: '#000000', // Texto secundario negro
    },
  },
  typography: {
    fontFamily: 'Inter, Roboto, Arial, sans-serif', // Fuente Inter
  },
});

export default theme;
